<script setup
        lang="ts">

</script>

<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <mask id="mask0_255_8803" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="currentColor"/>
  </mask>
  <g mask="url(#mask0_255_8803)">
    <path d="M8.0013 8.24477L11.5791 4.66699L12.668 5.75588L8.0013 10.4225L3.33463 5.75588L4.42352 4.66699L8.0013 8.24477Z" fill="currentColor"/>
  </g>
</svg>
</template>

<style scoped>

</style>